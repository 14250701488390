<script>
const buttonTypes = {
  error: defineAsyncComponent(() => import('./ErrorButton')),
  secondary: defineAsyncComponent(() => import('./SecondaryButton')),
  delete: defineAsyncComponent(() => import('./DeleteButton')),
  secondarySmall: defineAsyncComponent(() => import('./SecondarySmallButton')),
  primary: defineAsyncComponent(() => import('./PrimaryButton')),
};

export default defineNuxtComponent({
  name: 'Button',
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    buttonType: {
      type: String,
      default: '',
      required: true,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    iconColor: {
      type: String,
      default: '',
      required: false,
    },
    extraClass: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    buttonComponent() {
      return buttonTypes[this.buttonType];
    },
  },
  methods: {
    onClick() {
      if (this.disabled)
        return;

      this.$emit('click');
    },
  },
});
</script>

<template>
  <div @click="onClick">
    <component
      :is="buttonComponent"
      :label="label"
      :icon="icon"
      :icon-color="iconColor"
      :disabled="disabled"
      :class="{ 'cursor-not-allowed': disabled }"
      :extra-class="extraClass"
    />
  </div>
</template>
